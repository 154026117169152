<template>
  <label
    :id="uid"
    :class="[
      disabled
        ? `v-arora-input v-arora-select v-arora-input--${appConfig.VueSettingsPreRun.InputLayout} disabled`
        : `v-arora-input v-arora-select v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}`
    ]"
    :inert="disabled"
  >
    <select
      class="v-form-control"
      :aria-label="label.toString()"
      :data-test-id="dataTestId"
      v-model="picked"
    >
      <option
        v-if="defaultOption"
        selected
        :aria-selected="defaultOption"
        :disabled="defaultOptionDisabled"
        :inert="defaultOptionDisabled"
        :value="defaultOptionValue"
        v-html="defaultOptionText"
      />
      <option
        v-for="(item, index) in options"
        :value="item"
        :key="`${uid}-${index.toString()}`"
        :disabled="isItemDisabled(item)"
        :inert="isItemDisabled(item)"
      >
        <slot
          :value="item"
          name="option"
        />
      </option>
    </select>

    <span
      :class="`v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__label v-arora-select__label`"
    >
      <span>
        <slot name="label">
          <span
            class="v-pointer-events-none"
            v-html="label"
          />
        </slot>
        <span
          v-if="required"
          class="v-required"
        />
      </span>
    </span>
  </label>
</template>

<script setup lang="ts" generic="T">
import type { VElement } from '@arora/common'

const {
  defaultOptionDisabled = true,
  isItemDisabled = () => false,
  selected = null
} = defineProps<
  VElement & {
    selected?: T | null
    options?: T[]
    isItemDisabled?: (t: T) => boolean
    disabled?: boolean
    defaultOption?: boolean
    defaultOptionText?: string
    defaultOptionDisabled?: boolean
    defaultOptionValue?: T
  }
>()

const emit = defineEmits(['update:selected'])
const uid = useId()
const appConfig = useAppConfig()

const picked = computed<T | null>({
  get() {
    return selected
  },
  set(value) {
    emit('update:selected', value)
  }
})
</script>
